/*!
 * Start Bootstrap - Freelancer Bootstrap Theme (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */

body {
    overflow-x: hidden;
}

p {
    font-size: 20px;
}

p.small {
    font-size: 16px;
}

a,
a:hover,
a:focus,
a:active,
a.active {
    outline: 0;
    /*color: #18bc9c;*/
}



hr.star-light,
hr.star-primary {
    margin: 25px auto 30px;
    padding: 0;
    max-width: 250px;
    border: 0;
    border-top: solid 5px;
    text-align: center;
}

hr.star-light:after,
hr.star-primary:after {
    content: "\f005";
    display: inline-block;
    position: relative;
    top: -.8em;
    padding: 0 .25em;
    font-family: FontAwesome;
    font-size: 2em;
}

hr.star-light {
    border-color: #fff;
}
.rsvp {
width: 40%;
padding: 18px 25%;
margin:5%;
color: white;
text-align:center;
background-color:#219d09;
border-radius: 3px;
font-size:0.8em;
}
    .rsvp a {
    text-decoration:none;
    color:#fff;
    }
    .rsvp a:hover {
    text-decoration:none !important;
    color:#fff !important;
    }
.smalltitle {
font-size: 1.4em;
font-family: 'Poppins';
text-align: center;
width: 100%;

padding: 15px 0;
background-color: #202020;
color: white;
font-weight: 300;
/*text-transform: uppercase;*/
margin-bottom:19px;
}
hr.star-light:after {
    color: #fff;
    background-color: #18bc9c;
}

hr.star-primary {
    border-color: #2c3e50;
}

hr.star-primary:after {
    color: #2c3e50;
    background-color: #fff;
}

.img-centered {
    margin: 0 auto;
}

/* .overlay {
	background: url(../img/overlay.png);
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	z-index: 999;
	width: 100%;
	height: 100%;
} */
/* header {
    text-align: center;
    color: #fff;
    width: 100%;
    background-image:url(../img/US.jpg);
    background-attachment:fixed;
    background-size: cover;

} */

.btn-social {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 2px solid #fff;
    border-radius: 100%;
    text-align: center;
    font-size: 20px;
    line-height: 45px;
}

.btn:focus,
.btn:active,
.btn.active {
    outline: 0;
}

.scroll-top {
    z-index: 1049;
    position: fixed;
    right: 2%;
    bottom: 2%;
    width: 50px;
    height: 50px;
}

.scroll-top .btn {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    font-size: 20px;
    line-height: 28px;
}

.scroll-top .btn:focus {
    outline: 0;
}

.portfolio-modal .modal-content {
    padding: 100px 0;
    min-height: 100%;
    border: 0;
    border-radius: 0;
    text-align: center;
    background-clip: border-box;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.portfolio-modal .modal-content h2 {
    margin: 0;
    font-size: 3em;
}

.portfolio-modal .modal-content img {
    margin-bottom: 30px;
}

.portfolio-modal .modal-content .item-details {
    margin: 30px 0;
}

.portfolio-modal .close-modal {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 75px;
    height: 75px;
    background-color: transparent;
    cursor: pointer;
}

.portfolio-modal .close-modal:hover {
    opacity: .3;
}

.portfolio-modal .close-modal .lr {
    z-index: 1051;
    width: 1px;
    height: 75px;
    margin-left: 35px;
    background-color: #2c3e50;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.portfolio-modal .close-modal .lr .rl {
    z-index: 1052;
    width: 1px;
    height: 75px;
    background-color: #2c3e50;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
