
h1 {
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 17px;
}
h2 {
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 17px;
}
h3 {
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 17px;
}
p {
  font-size: 16px;
  line-height: 1.4;
  padding-left: 15px  0;
}
.top-90 {
  padding-top: 90px;
}
.bottom-90 {
  padding-bottom: 90px
}
.bot-0 {
  margin-bottom: 0!important;
}
.line-height-15 {
  line-height: 2.1;
}
.can-toggle {
  position: relative;
width: 204px;
margin-left: calc(50% - 102px);
border: 0px solid #e0e0e0;
border-radius: 30px;
}
.can-toggle *, .can-toggle *:before, .can-toggle *:after {
  -moz-box-sizing: border-box;
       box-sizing: border-box;
}
.can-toggle input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.can-toggle input[type="checkbox"][disabled] ~ label {
  pointer-events: none;
}
.can-toggle input[type="checkbox"][disabled] ~ label .can-toggle__switch {
  opacity: 0.4;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:before {
  content: attr(data-unchecked);
  left: 0;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  content: attr(data-checked);
}
.can-toggle label {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.can-toggle label .can-toggle__label-text {
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding-left: 32px;
}
.can-toggle label .can-toggle__switch {
  position: relative;
}
.can-toggle label .can-toggle__switch:before {
  content: attr(data-checked);
  position: absolute;
  top: 0;
  text-transform: uppercase;
  text-align: center;
}
.can-toggle label .can-toggle__switch:after {
  content: attr(data-unchecked);
  position: absolute;
  z-index: 5;
  text-transform: uppercase;
  text-align: center;
  background: #fed008;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.can-toggle input[type="checkbox"][disabled] ~ label {
  color: rgba(119, 119, 119, 0.5);
}
.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch, .can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch {
  background-color: #fff;
}
.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch:after, .can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
  color: #000;
}
.can-toggle input[type="checkbox"]:hover ~ label {
  color: #000;
}
.can-toggle input[type="checkbox"]:checked ~ label:hover {
  color: #000;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch {
  background-color: #fff;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  color: #000;
}
.can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch, .can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch {
  background-color: #fff;
}
.can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
  color: #000;
}
.can-toggle label .can-toggle__label-text {
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.can-toggle label .can-toggle__switch {
  -webkit-transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
          transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
  background: #fff;
}
.can-toggle label .can-toggle__switch:before {
  color: rgba(255, 255, 255, 0.5);
}
.can-toggle label .can-toggle__switch:after {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  color: #000;
}
.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch:after, .can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch:after {

}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  -webkit-transform: translate3d(96px, 0, 0);
          transform: translate3d(96px, 0, 0);
}
.can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {

}
.can-toggle label {
  font-size: 14px;
}
.can-toggle label .can-toggle__switch {
  height: 36px;
  -webkit-flex: 0 0 200px;
      -ms-flex: 0 0 200px;
          flex: 0 0 200px;
  border-radius: 25px;
}
.can-toggle label .can-toggle__switch:before {
  left: 98px;
  font-size: 12px;
  line-height: 36px;
  width: 103px;
  padding: 0 12px;
  color:  #777;
}
.can-toggle label .can-toggle__switch:after {
  top: 2px;
  left: 2px;
  border-radius: 25px;
  width: 100px;
  line-height: 32px;
  font-size: 12px;
}
.can-toggle label .can-toggle__switch:hover:after {

}
.bottom-15px {
  margin-bottom: 25px;
}
.divider {
  width: 100%;
  float: left;
  border-bottom: 1px solid #e3e3e3;
  margin-top: 50px;
  margin-bottom: 50px;
  height: 1px;
}
.no-pad {
  padding-left: 0!important;
  padding-right: 0!important;
}
.glow {
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.4);
-webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.4);
-moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.4);
}
.icon-img {
  padding: 15px;
  float: left;
  width: 100%;
}
.h66 {
  height: 66px;
  margin-top: 10px;
}
.white-bg {
  background: #fff;
}
.icon-img img {
      margin: 0px auto 0;
      max-height: 100%;
      width: auto;
}
.prod-title {
  font-size: 20px;
  color: #000;
  padding: 0 0 5px 0;
  width: 100%;
  float: left;
      font-weight: 500;
      line-height: 1.4;
}
.con-title {
  font-size: 15px;
  color: #222;
  padding: 15px 0 5px 0;
  width: 100%;
  float: left;
  line-height: 1.4;
}
.prod-disc {
  font-size: 14px;
  color: #999;
line-height: 1.3;
padding: 6px 0 10px 0;
width: 100%;
float: left;
}
.article-head {
  position: relative;
  float: left;
  width: 100%;
}
.article-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
  color: #fff;padding: 40px 20px 10px;
  line-height: 1.5;
  font-weight: 200;
}
.use-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgb(0 0 0 / 43%) 100%);;
  color: #fff;padding: 40px 20px 10px;
  line-height: 1.5;
  font-weight: 200;
}

.article-bottom  span, .use-bottom span {
  font-weight: 700;
}
.con-disc {
  font-size: 11px;
  color: #999;
line-height: 1.3;
padding: 6px 0 10px 0;
width: 100%;
float: left;
}
.prod-price {
  font-size: 18px;
  font-weight: 300;
  color: #000;
  width: 100%;
  float: left;
}
.find {
  position: absolute;
  right: calc(50% - 18px);
  top: 24px;
  width: auto;
  height: 30px;
}
.top-100px {
  margin-top: 100px;
}
.center-flex {
  display:flex; align-items: Center;
  text-align: center;
  border-bottom: 1px solid #eaeaea;
}
.bin-btn {
  background-color: #ff5050;
    padding: 8px;
    border-radius: 10px;
    border: none;
    width: 40px;
    margin-left: 20%;
}
.bin-btn img {
  width: 15px;
  height: auto;
}
.icon-label {
  color: black;
  text-align: center!important;
  line-height: 1.3;
  margin: 10px 0;
  float: left;
  width: 100%;
}
.default-btn {
  width: 200px;
  background-color: #0066a9;
  color: #fff;
  padding: 12px;
  border-radius: 30px;
  border: none;
  margin-bottom: 10px;
}
.hero-btn {
  background-color: #0066a9;
  color: #fff;
  padding: 12px;
  border-radius: 30px;
  border: none;
  margin-bottom: 10px;
}
.inactive {
  opacity: 0.5;
}
input[type="text"] {
  border: none;
  padding: 0px 20px!important;
  border-bottom: 1px solid #000!important;
  width: 86%;
  margin-bottom: 10px;
  height: 50px;
  background: #f4f4f4;
  margin-left: 7%;
  border-radius: 0;
}

input[type="number"] {
  border: none;
  padding: 0px 20px!important;
  border-bottom: 1px solid #000!important;
  width: 86%;
  margin-bottom: 10px;
  height: 50px;
  background: #f4f4f4;
  margin-left: 7%;
  border-radius: 0;
}

input::placeholder {
  color: #acacac!important;
}
input:focus, select:focus {
  outline: none!important;
}
select {
  border: none!important;
  padding: 0 15px!important;
  border-bottom: 1px solid #000!important;
  background-color: #f4f4f4!important;
  margin-bottom: 10px!important;
  height: 50px!important;
  width: 86%!important;
  margin-left: 7%!important;
  border-radius: 0!important;
}
input[type="textarea"] {
  border: none;
  background-color: #f9f9f9;
  border-radius: 0;
  width: 100%;
  padding: 5px 7px;
  border-bottom: 2px solid #000;
  min-height: 100px;
}
.progressbar {
  counter-reset: step;
}
.progressbar li {
  list-style: none;
  display: inline-block;
  width: 29.33%;
  position: relative;
  text-align: center;
  cursor: pointer;
}
.progressbar li:before {
  content: counter(step);
    counter-increment: step;
    width: 30px;
    height: 30px;
    line-height: 28px;
    border: 1px solid #ddd;
    border-radius: 100%;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    background-color: #000;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
}
.progressbar li:after {
  content: "";
  position: absolute;
  width: 87%;
  height: 1px;
  background-color: #ddd;
  top: 15px;
  left: -50%;
  z-index : 2;
}
.upload {
  background: #000;
  color: #fff;
  padding: 9px 14px;
  position: absolute;
right: 0px;
top: -7px;
border: None;
border-radius: 22px;
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.active {

}
.progressbar li.active:before {
background-color: #fed008;
color: #000;
border: none;
}
.progressbar li.active + li:after {
  border-color: #fed008;
    background: #fed008;
    color: #000;
}

.ticket {
  width: 100%;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 15px;
}
.ticket-title, .article-title {
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: 400;
}
.ticket-id {
  font-size: 20px;
  font-weight: 600;
  padding: 10px 0;
}

.ticket-detail {
  font-size: 13px;
  padding: 0 0 10px 0;
  color: #999;
  line-height: 1.3
}

.mega-title {
font-size: 40px;
font-weight: 800;
padding: 60px 0;
color: #fff;
text-align: center;
background-color: #377ea5;
}
.mega-title p {
  font-weight: 300;
  line-height: 1.5;
  font-size: 14px;
  margin-top: 20px;
}

a.ticket-link {
  font-size: 12px;
  text-decoration: none;
  font-weight: 700;
  margin-top: 15px;
  color: #000;
}
.ticket-warning {
  border-left: 5px solid orange;
}
.ticket-warning .ticket-detail {
  color: orange;
}

.ticket-danger {
  border-left: 5px solid red;
}
.ticket-danger .ticket-detail {
  color: red;
}
.ticket-success {
  border-left: 5px solid green;
}
.ticket-success .ticket-detail {
  color: green;
}
.no-action {
  opacity: 0.4;
}
.low-grey {
  background-color: #ededed;
}
.pad-15 {
  padding: 15px;
}
/* .article-header {
  background-image: url(../images/article.jpg);
  background-size:cover;
  background-repeat: no-repeat;
  border-radius: 14px 14px 0 0;
  height: 200px;
  width: 100%;
} */
.article-heading {
  font-weight: 300;
  font-size: 22px;
  line-height: 1.5;
  margin-top: 7px;
  margin-bottom: 0;
}

.article-title {
  margin-top: 20px;
  font-size: 13px;
}

.article {
  border-radius: 14px 14px 0 0;
}
.article p {
  padding-top: 0;
  margin-top: -10px;
  margin-bottom: 10px;
  color:#666;
}
.pagination>li>a, .pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #000000;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
    font-size: 13px;
}
.wrapper {
  max-width: 34rem;
  width: 100%;
  margin: 50px auto;
  padding: 30px;
}
.tabs {
  position: relative;
  margin: 3rem 0;
  background: #d0342b;
  height: auto;
}
.tabs::before,
.tabs::after {
  content: "";
  display: table;
}
.tabs::after {
  clear: both;
}
.tab {
  float: left;
  width: 33.33333%;
}
.no-bod {
  border-right: none!important;
}
.tab-switch {
  display: none;
}
.tab-label {
  position: relative;
  display: block;
  line-height: 2.75em;
  height: 3em;
  padding: 0 1.618em;
  background: #d0342b;
border-right: 0.125rem solid #922f29;
  color: #fff;
  cursor: pointer;
  top: 0;
  transition: all 0.25s;
}
.tab-label:hover {

  transition: top 3.25s;
  background-color: #a03333;
}
/* .tab-content {
  height: auto;
  position: absolute;
  z-index: 1;
  top: 2.75em;
  left: 0;
  padding: 1.618rem;
  background: #fff;
  color: #2c3e50;
  border-bottom: 0.25rem solid #bdc3c7;
  opacity: 0;
  transition: all 0.35s;
} */
.tab-switch:checked + .tab-label {
  background: #fff;
  color: #0066a9;
  border-bottom: 0;
  border-right: 0.125rem solid #fff;
  transition: all 0.35s;
  z-index: 1;
}
/* .tab-switch:checked + label + .tab-content {
  z-index: 2;
  opacity: 1;
  transition: all 0.35s;
} */
.big-pad {
  padding: 30px;
}
.bottom-30 {
  margin: 10px 0 30px!important;
}
.explaina {
  font-size: 17px;
  color: #6992c6;
  padding: 0 20px;
  line-height: 1.3;
}
.steps {
  width: 150px;
  border: 1px solid #2f80e8;
  margin-left: calc(50% - 75px);
  float: left;
  border-radius: 10px;
  margin-top: -10px;
}
.rate {
  background: #2f80e8;
  height: 7px;
  float: left;
}
.explaina-red {
  font-size: 17px;
  color: #d13535;
  padding: 0 20px;
  line-height: 1.3;
}
.steps-red {
  width: 150px;
  border: 1px solid #d13535;
  margin-left: calc(50% - 75px);
  float: left;
  border-radius: 10px;
  margin-top: -10px;
}
.rate-red {
  background: #d13535;
  height: 7px;
  float: left;
}
.next-red {
  padding: 15px 23px;
  color: #fff;
  background-color: #d13535;
  border-radius: 6px;
  position: absolute;
  right: 0;
  bottom: -71px;
  border: none;
}
.back-red {
  padding: 15px 23px;
  color: #fff;
  background-color: #d13535;
  border-radius: 6px;
  position: absolute;
  left: 0;
  bottom: -71px;
}
.back-red:hover, .next-red:hover {
  color: #fff;
  background-color: #7c1010;
}
.relative {
  position: relative;
}
.back {
  padding: 15px 23px;
  color: #fff;
  background-color: #2f80e8;
  border-radius: 6px;
  position: absolute;
  left: 0;
  bottom: -71px;
}
.next {
  padding: 15px 23px;
  color: #fff;
  background-color: #2f80e8;
  border-radius: 6px;
  position: absolute;
  right: 0;
  bottom: -71px;
}
.ghosted {
  background-color: #999!important;
}
.back:hover, .next:hover {
  color: #fff;
  background-color: #1f58a1!important;
}
.wizard {
  margin-bottom: 95px;
}
.mg-bottom-20 {
  margin-bottom: 20px;
}
.top-60 {
  padding-top: 60px!important;
}
#work .heading {
  color: #000!important; padding: 30px  0
}
.col-md-12 {
  width: 100%;
}
.my-checkbox {
  transform: scale(2);
  margin-right: 11px!important;
}
.line {
  float: left;
    width: 80%;
    margin-left: 11%;
    padding-bottom: 15px;
}

footer {
  text-align: center;
  background-color: #fff!important;
  font-size: 12px!important;
  color: white;
  width: 100%;
  float: left;
  padding: 5px 0 20px;
}
footer p {
  padding: 10px 0 0 0;
}
footer a {
  color: #0d30be;
  font-size: 15px;
}
.heading {
  text-transform: capitalize;
}
ul.why li {
  line-height: 22px;
  font-size: 16px;
  font-weight: 400!important;
  color:#000;
  line-height: 22px;
  list-style: outside;
  margin: 0 0 0 46px;
}
.why {
  margin-top: 12px;
    float: left;
    
}
textarea {
  height: 150px;
  border: none !important;
  padding: 5px 7px !important;
  border-bottom: 2px solid #000 !important;
  background: #ededed !important;
  width: 100% !important;
  padding: 15px !important;
  width: 86% !important;
  border-radius: 0 !important;
  margin-bottom: 10px !important;
  background: #f4f4f4 !important;
  margin-left: 7% !important;
}


.go-mid {
  margin: 0 auto;
}
.burger-right {
  margin-left: calc(100% - 56px);
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  border: 1px solid #8976f9;
  box-shadow: none!important;
}
.home-section li {
  list-style: none;
  margin: 0;
  width: 33.3332%;
}
.home-section li button {
  width: 100%;
  border-radius: 0!important;
}
.tab-content {
  padding: 20px;
  background-color: #fff;;
}
.home-section .nav-tabs {
  background-color: #d0342b;
}
.home-section .nav-link {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border-right: 1px solid #922f29;
}

.home-section .nav-tabs .nav-link.active, .home-section .nav-tabs .nav-link.active:hover {
  color: #0066a9;
  background-color: #fff;
}

.home-section .nav-tabs .nav-link:hover {
  border-color: transparent;
  border-right: 1px solid #922f29;
  background-color: #78140d;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: 0;}

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 15px;
    padding-left: 15px;
    font-weight: 400;
    color: black;
}
.mini-logo {
width: 111px;
}

.form-group {
  float: left;
  width: 100%;
  margin-bottom: 10px;
}

select {
  appearance:  auto!important;
}
#work .terms p {
  text-align: left!important;
}
.img-responsive {
  max-width: 100%;
}

nav {
  box-shadow: 0 0 2px rgba(50, 50, 50, 0.4);
}