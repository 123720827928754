/* Template 2075 Digital Team http://www.tooplate.com/view/2075-digital-team */
body {
    background: #ffffff;
    font-family: 'Jost'!important;
    font-weight: 300;
    position: relative;
}
.fullwidth {
    width: 100%;
    float: left;
}
.fullwidth .heading {
    padding: 40px 0 -20px;
    float: left;
    width: 100%;
}
.blue-back {
 background: #06182aa3;
   padding: 35px;
}
.finance-top {
   /* background-image: url(../images/finance-top.jpg); */
   background-size: cover;
   background-position: center;
   color: #ffffff;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   -webkit-align-items: center;
   -ms-flex-align: center;
   align-items: center;
   text-align: center;
   text-transform: uppercase;
   font-weight: 900;
}
.pink {
    background-color: transparent;
}
.green {
    background-color: transparent;
}
.yellow {
    background-color: transparent;
}
.blue {
    background-color: transparent;
}
.modal-open {
    max-height: 100vh!important;
    overflow-y: hidden!important;
}
.rounded {
    border-radius: 100%;
    padding: 0 10%;
    transition: all .2s ease-in-out;
}
.register {
    color: #fff;
    padding: 3px 15px!important;
    border: 1px solid #fff;
    border-radius: 4px;
    margin-top: 10px;
}
.info-block {
    border: 1px solid #fff;
    border-radius: 44px;
    text-align: center;
    background-color: #ffffff3d;
    padding: 20px;
    color: #fff!important;
    margin-bottom: 20px;
    width: 100%;
    float: left;
    cursor: pointer;
    font-weight: 400;
    font-size: 15px;
}

.shadow {
 -webkit-box-shadow: 2px 7px 72px 33px rgba(0,0,0,1);
 -moz-box-shadow: 2px 7px 72px 33px rgba(0,0,0,1);
 box-shadow: 2px 7px 72px 33px rgba(0,0,0,1);
}
.btn-hover {
    width: 100px;
    font-size: 16px;
    font-weight: 600;
    color: #fff!important;
    cursor: pointer;
    margin: 0 20px;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    border-radius: 4px;
    transition: all .4s ease-in-out;
    padding: 6px!important;
    margin-top: -1px;
    moz-transition: all .4s ease-in-out!important;
    -o-transition: all .4s ease-in-out!important;
    -webkit-transition: all .4s ease-in-out!important;
    transition: all .4s ease-in-out!important;
}
.btn-hover.color-2 {
  border: 1px solid #000;
 color: #000!important;
 border-radius: 30px;
}
.btn-hover:hover {
    background-color:#000!important;
      color: #fff!important;
}
.height100 {
    height: 100%;
}
.btn-hover:focus {
    outline: none;
}
.info-block:hover {
    border: 1px solid #58b316;
    background: #58b316;
    border-radius: 44px;
    text-align: center;
    padding: 20px;
    color: #fff;
    margin-bottom: 20px;
}
section:focus {
    outline: -webkit-focus-ring-color auto 0px;
}
.register:hover {
    color: #666!important;
    padding: 3px 15px;
    border: 1px solid #fff;
    border-radius: 4px;
    margin-top: 10px;
    background: #fff!important;
}
.modal-image {
    max-width: 350px;
}
.rounded:hover {
    transform: scale(1.1);
}
.teamer {
    font-size: 16px;
    color:#333;
    width: 100%;
    float: left;
    margin-top: 15px;
    margin-bottom: 30px;
}
video {
    opacity:0.4;
}
.grey {
    background-color: #ececec;
    margin-bottom: 15px;
}
.modal-head {
    /* background-image: url(../images/edu-top.jpg);  */
    background-size: cover;
    background-position: center;
    color: #ffffff;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    font-weight: 900;
}
.call-top {
   /* background-image: url(../images/call-top.jpg); */
   background-size: cover;
   background-position: center;
   color: #ffffff;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   -webkit-align-items: center;
   -ms-flex-align: center;
   align-items: center;
   text-align: center;
   text-transform: uppercase;
   font-weight: 900;
}
.device-top {
   /* background-image: url(../images/device-top.jpg); */
   background-size: cover;
   background-position: center;
   color: #ffffff;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   -webkit-align-items: center;
   -ms-flex-align: center;
   align-items: center;
   text-align: center;
   text-transform: uppercase;
   font-weight: 900;
}
.study-top {
   /* background-image: url(../images/study-top.jpg); */
   background-size: cover;
   background-position: center;
   color: #ffffff;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   -webkit-align-items: center;
   -ms-flex-align: center;
   align-items: center;
   text-align: center;
   text-transform: uppercase;
   font-weight: 900;
}
.promm-top {
   /* background-image: url(../images/promm-top.jpg); */
   background-size: cover;
   background-position: center;
   color: #ffffff;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   -webkit-align-items: center;
   -ms-flex-align: center;
   align-items: center;
   text-align: center;
   text-transform: uppercase;
   font-weight: 900;
}
.short-top {
   /* background-image: url(../images/short-top.jpg); */
   background-size: cover;
   background-position: center;
   color: #ffffff;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   -webkit-align-items: center;
   -ms-flex-align: center;
   align-items: center;
   text-align: center;
   text-transform: uppercase;
   font-weight: 900;
}
.execu-top {
   /* background-image: url(../images/execu-top.jpg); */
   background-size: cover;
   background-position: center;
   color: #ffffff;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   -webkit-align-items: center;
   -ms-flex-align: center;
   align-items: center;
   text-align: center;
   text-transform: uppercase;
   font-weight: 900;
}
.finance-top {
   /* background-image: url(../images/finance-top.jpg); */
   background-size: cover;
   background-position: center;
   color: #ffffff;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   -webkit-align-items: center;
   -ms-flex-align: center;
   align-items: center;
   text-align: center;
   text-transform: uppercase;
   font-weight: 900;
}
.tools-top {
   /* background-image: url(../images/tools-top.jpg); */
   background-size: cover;
   background-position: center;
   color: #ffffff;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   -webkit-align-items: center;
   -ms-flex-align: center;
   align-items: center;
   text-align: center;
   text-transform: uppercase;
   font-weight: 900;
}
.mba-top {
   /* background-image: url(../images/mba-top.jpg); */
   background-size: cover;
   background-position: center;
   color: #ffffff;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   -webkit-align-items: center;
   -ms-flex-align: center;
   align-items: center;
   text-align: center;
   text-transform: uppercase;
   font-weight: 900;
}
#fund-back {
 /* background-image: url(../images/fund-back.jpg); */
 background-size: cover;
 background-position: center;
 height: 100vh;
 width: 100%;
 color: #ffffff;
 display: -webkit-flex;
 display: -ms-flexbox;
 display: flex;
 -webkit-align-items: center;
 -ms-flex-align: center;
 align-items: center;
 text-align: center;
}
#fund-project {
 height: 100vh;
 width: 100%;
 background:#f7f7f7;
 display: -webkit-flex;
 display: -ms-flexbox;
 display: flex;
 -webkit-align-items: center;
 -ms-flex-align: center;
 align-items: center;
 text-align: center;
}
#fund-mission {
padding: 80px 0 100px;
 width: 100%;
 background:#fff;
 display: -webkit-flex;
 display: -ms-flexbox;
 display: flex;
 -webkit-align-items: center;
 -ms-flex-align: center;
 align-items: center;
 text-align: center;
}
ul.miss li{
 text-align: left!important;
 padding-left: 40px;
}
.white-background {
 background: #fff;
 padding: 25px;
}
.white-text {
 color: #fff!important;
}
p {
 color: #000!important;
}
.head {
 font-size: 48px;
   margin-bottom: 8px;
   font-family: 'Jost';
   margin-top: 8px;
   text-transform: uppercase;
   font-weight: 800!important;
   text-align: center;
       line-height: 1.7;
}
.page-space {
 margin: 80px auto 90px;
}
.fund-paragraph {
 font-size: 18px; font-weight: 300!important; line-height: 1.4;
}
.fund-title {
 font-size: 27px; font-weight: 800!important; line-height: 0.7;
}
.vh80{
 height: 80vh;
}
.vh100{
 height: 100vh;
}
.flex-mid {
 display: flex;
 align-items: center;
}
.card-top {
    /* background-image: url(../images/card-top.jpg); */
}
.card-top2 {
   /* background-image: url(../images/card-top2.jpg); */
}
.fund-top {
   /* background-image: url(../images/fund-top.jpg); */
}
.burs-top {
    /* background-image: url(../images/bur-top.jpg); */
}
.burs-top2 {
   /* background-image: url(../images/burs-top2.jpg); */
}
.legal-top {
   /* background-image: url(../images/legal-top.jpg); */
}
.calc-top {
   /* background-image: url(../images/calc-top.jpg); */
 }
.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}
.room-top {
    /* background-image: url(../images/room-top.jpg); */
}
.fundi-top {
    /* background-image: url(../images/fundi-top.jpg); */
}
.promo-top {
    /* background-image: url(../images/promo-top.jpg); */
}
.top-30 {
    padding-top: 30px;
}
.modal-heading {
    text-transform: uppercase;
    font-weight: 800;
    width: 100%;
    color: #12375d!important;
}
.modal-title {
    font-size: 40px;
    font-weight: 200;
    line-height: 1.2;
    width: 70%;
    margin: 0 auto;
    padding: 150px 0;
    text-shadow: 0px 1px 0px #000;
}
.team-title {
 font-size: 17px;
 font-weight: 800;
 padding-bottom: 7px;
}
.calc-top .modal-title {
    padding:120px 0 107px;
}
.red {
    background-color: #e0614b!important;
    width: auto!important;
    padding: 12px 42px!important;
}
.para {
    line-height: 1.4;
    padding: 33px;
    float: left;
}
html, body {
    width: 100%;
    overflow-x: hidden;
}
h3 {
    font-size: 18px;
}
/* Carousel Styles */
/* Carousel Header Styles */
.glyphicon {
    font-size: 50px!important;
}
.carousel-indicators {
    bottom: 40px!important;
}
.header-text {
    position: absolute;
    top: 44%;
    left: 1.8%;
    right: auto;
    width: 96.66666666666666%;
    color: #fff;
}
.hero {
 position: absolute; top: 37%; font-size: 32px; width: 100%; text-align: center; font-weight: 200; padding: 0 20px; line-height: 1.3;
}
.carousel-item {
    height: 65vh;
    min-height: 350px;
    background: no-repeat center center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.header-text h2 {
    font-size: 40px;
}
.header-text h2 span {
    background-color: #2980b9;
    padding: 10px;
}
.header-text h3 span {
    background-color: #000;
    padding: 15px;
}
.btn-min-block {
    min-width: 170px;
    line-height: 26px;
}
#team h4 {
    text-align: center;
    font-weight: 800;
    font-size: 19px;
    color: #000;
}

#team img {
    border: 12px solid #e2e2e2;
    border-radius: 100%;
    width: 80%;
    margin: 45px 10% -10px 10%;
}
#team h5 {
    text-align: center;
    font-size: 16px;
    color: #999;
    font-weight: 700;
    padding: 10px 0 5px;
    text-transform: uppercase;
}
#team p {
    font-size: 14px;
}
.btn-theme {
    color: #fff;
    background-color: transparent;
    border: 2px solid #fff;
    margin-right: 15px;
}
.btn-theme:hover {
    color: #000;
    background-color: #fff;
    border-color: #fff;
}
.itemu {
    font-size: 32px;
    animation-duration: 20s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    text-transform: uppercase;
    font-weight: 800;
    line-height: 1;
}
.da-icon {
    width: 240px;
    margin: 0 auto;
}
.da-icon img {
    width: 100%;
    height: auto;
    float: left;
    margin-bottom: -30px;
}
.pamwe {
    width: 100%;
    float:left;
    font-size: 16px;
    font-weight: 800;
    color: #cc5151;
    text-transform: uppercase;
}
.pamwe span {
    font-size: 65px;
}
#btn-close-modal {
    width:100%;
    text-align: center;
    cursor:pointer;
    color:#fff;
    padding:40px 0;
}
.closebt {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    cursor: pointer;
   /* margin-top: 20px;
    */
    position: absolute;
    z-index: 88888;
    top: 20px;
    right: 22px;
}
.closebt:hover {
    transform: rotate(90deg);
}
#animatedModal {
    background: white!important;
}
h1, h2, h3, h4 {
    font-weight: 200;
    margin-top: 29px;
}
h1 i {
    color: #ff5353;
}

.heading {
   font-size: 28px;
   color: #12375d!important;
   margin-bottom: 8px;
   font-family: 'Jost';
   margin-top: 8px;
   font-weight: 800!important;
   text-align: center;
}
.med-heading {
   font-size: 48px;
   color: #12375d!important;
   margin-bottom: 8px;
   font-family: 'Jost';
   margin-top: 8px;
   text-transform: uppercase;
   font-weight: 800!important;
   text-align: center;
}

.heading-left {
    font-size: 18px;
    color: #717070!important;
    margin-bottom: 8px;
    font-family: 'Jost';
    margin-top: -3px;
    font-weight: 800!important;
    text-align: left!important;
}
.heading-right {
    font-size: 18px;
    color: #717070!important;
    margin-bottom: 8px;
    font-family: 'Jost';
    margin-top: -3px;
    font-weight: 800!important;
    text-align: right!important;
}
.text-left {
    text-align: left!important;
}
.text-right {
    text-align: right!important;
}
.st0{
   fill:none;
   stroke-miterlimit:10;
}
.subheading {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 60px;
}
.bold {
    font-weight: 600;
}
.logo{
    margin-top: 5px;
    margin-left: 9px;
    FONT-SIZE: 28PX;
    color: white;
    line-height: 1.7!important;
}
a, a:visited, a:active, a:hover {
    text-decoration: none!important;
}
.rogo {
    line-height: 1.5;
    font-size: 20px;
    float: left;
    width: 194px;
    margin-left: 32px;
    margin-top: -1px;
    padding-left: 7px;
    font-weight: 800;
    color: #fff;
    padding-left: 11px;
}
.globe {
    fill:#FFFFFF;
}
.text-center {
   text-align: center;
}
.call-to-action {
   line-height: 1.5;
   font-size: 16px;
   color: #0066a9!important;
   font-weight: 400;
}
.top-nav-collapse .rogo {
    color: #7d7d7d;
    border-left: 0px solid #FFF;
    border-left-style: solid;
}
.top-nav-collapse .globe {
    fill: #777;
}
#work hr {
    float: left;
    border-top: none;
    background: -webkit-linear-gradient(135deg, #359ed4, #12ff00);
    background: linear-gradient(135deg, #359ed4, #12ff00);
}
#work p {
    width: 100%;
    float: left;
}
p {
    line-height: 22px;
    font-size: 14px;
    font-weight: 400!important;
    color:rgba(3,27,78,.7);
}
.know-more {
    color: #0069ff;
    font-size: 14px;
    font-weight: 500;
    margin-top: 0px;
    width: 100%;
}
#info img {
   margin-top: 55px;
}
.go-left {
    float: left;
}
a {
    outline: none !important;
}
hr {
    width: 220px;
    height: 20px;
    background-color: #fff;
    border-bottom: 1px solid #e0dcdc!important;
    border-top: none!important;
}
.top20 {
    padding-top: 15px;
    padding-bottom: 45px;
    width: 100%;
    float: left;
    margin-bottom: 30px;
}
.section-title strong {
    color: #00C6D7;
    font-size: 56px;
}
.section-title h1 {
}
.section-title hr {
    background-color: #6e71f1;
}
.section-title {
    padding-bottom: 32px;
}
.medium-icon {
    font-size: 32px !important;
}
.for-mobile {
    display: none;
}
#work, #about, #team, #clients, #promo, #portfolio, #pricing, #contact, #info, #fund {
    padding-top:70px;
    padding-bottom: 90px;
}
.nav>li>a {
    position: relative;
    display: block;
    padding: 10px 12px!important;
}
#info {
    background-color: #fff;
    height: auto;
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
}
#info .top-edge {
    color: #fff;
}
.member {
    border-radius: 39px;
    margin:20px auto;
    text-align: center;
    padding: 6px 24px;
    color: #0eadef!important;
    border: 2px solid #0eadef;
    cursor: pointer;
    font-weight: 500;
    background-color:rgba(255, 255, 255, 0);
}
.member-left {
    border-radius: 39px;
    float: left;
    text-align: center;
    padding: 6px 24px;
    color: #ca1f1f!important;
    border: 2px solid #ca1f1f;
    cursor: pointer;
    font-weight: 500;
    background-color:rgba(255, 255, 255, 0);
}
.member-right {
    border-radius: 39px;
    text-align: center;
    padding: 6px 24px;
    color: #fff!important;
    border: 0px solid #ca1f1f;
    cursor: pointer;
    font-weight: 500;
    background: rgb(41,140,224);
    background: linear-gradient(20deg, rgba(41,140,224,1) 0%, rgba(93,168,227,1) 0%, rgba(0,212,255,1) 100%);
}
.bottom-off{
    margin-bottom: -40px;
    width: 100%;
}
.iconogram {
    width:70px;
    /* float left; */
    margin-left: calc(50% - 35px);
    margin-bottom: 20px;
    float: left;
}
/* ========================================================================== preloader section ========================================================================== */
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row nowrap;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background: none repeat scroll 0 0 #fff;
}
.sk-spinner-circle.sk-spinner {
    width: 22px;
    height: 22px;
    position: relative;
}
.sk-spinner-circle .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.sk-spinner-circle .sk-circle:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 20%;
    height: 20%;
    background-color: #00c6d7;
    border-radius: 100%;
    -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out;
    animation: sk-circleBounceDelay 1.2s infinite ease-in-out;
   /* Prevent first frame from flickering when animation starts */
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.sk-spinner-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
}
.sk-spinner-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
}
.sk-spinner-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}
.sk-spinner-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
    transform: rotate(120deg);
}
.sk-spinner-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
    transform: rotate(150deg);
}
.sk-spinner-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}
.sk-spinner-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
    transform: rotate(210deg);
}
.sk-spinner-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
    transform: rotate(240deg);
}
.sk-spinner-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
}
.sk-spinner-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
    transform: rotate(300deg);
}
.sk-spinner-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
    transform: rotate(330deg);
}
.sk-spinner-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}
.sk-spinner-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}
.sk-spinner-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}
.sk-spinner-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}
.sk-spinner-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
}
.sk-spinner-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
}
.sk-spinner-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}
.sk-spinner-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
}
.sk-spinner-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}
.sk-spinner-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}
.sk-spinner-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
}
@-webkit-keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
   }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
   }
}
@keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
   }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
   }
}
/* ========================================================================== navigation section ========================================================================== */
.custom-navbar {
    background: #fff;
   /* Old browsers */
    transition-duration: 1000ms!important;
    margin-bottom: 0;
}
.custom-navbar .navbar-brand {
    color: #00c6d7;
    font-weight: 600;
    font-size: 24px;
}
.custom-navbar .navbar-brand .navbar-toggle {
    background: #6e71f1;
    padding: 4px 6px;
    font-size: 16px;
    color: #fff;
}
.custom-navbar .nav li a {
  color: #000;
    font-size: 16px;
    font-weight: 400;
    padding-top: 12px;
    padding-bottom: 11px;
}
#work .row {
   
}
#info h1, #info p {
    font-family: 'Jost';
    text-align: center;
    line-height: 1.5;
    color: rgba(3,27,78,.7);
    font-weight: 500;
    float: left;
    width: 100%;
    font-size: 14px;
}
#work p {
   font-family: 'Jost';
    text-align: center;
    line-height: 1.5;
    color: rgba(3,27,78,.7);
    float: left;
    width: 100%;
    text-transform: none;
    margin-bottom: 40px;
    padding: 0 25px;
}
#work h2 {
    text-align: center!important;
}
.hona {
   padding: 10px 30px;
   border: 1px solid blue;
   color: blue;
   border-radius: 30px;
}
#work h1, #client h1, #form h1 {
  font-size: 33px;
 font-weight: 400!important;
 line-height: 1;
}
#info h1{
    font-size: 48px;
    font-weight: 800!important;
    line-height: 1;
    text-transform: uppercase;
    color: #000!important;
}
#work h2{
    font-size: 15px;
    font-weight: 600!important;
    line-height: 1.2;
    text-transform: uppercase;
    color: #2f84c8!important;
    margin-bottom: 11px;
}
#info h2 {
    font-size: 15px;
    font-weight: 600!important;
    line-height: 1.2;
    color:#12375d!important;
    margin: 11px 0;
}
.custom-navbar .nav li a.smoothScroll:hover {
    background: transparent;
}
.custom-navbar .nav > li > a:focus {
    background-color: transparent;
    color: #666;
    outline: none;
}
.top-nav-collapse .st0 {

}
.navbar-toggle .icon-bar {
   display: block;
   width: 22px;
   height: 2px;
   border-radius: 1px;
   padding: 0;
}
.top-nav-collapse .st2 {

}
.custom-navbar .nav li.active > a {
    color: #2f84c8;
    opacity: 0.99;
}
.custom-navbar .navbar-toggle {
    border: none;
    padding-top: 10px;
}
.custom-navbar .navbar-toggle .icon-bar {
    background: #000;
    border-color: transparent;
}
@media(min-width:768px){
    .custom-navbar {
      padding: 12px 0;
      background: #fff;
      color: #666!important;
      transition-duration: 700ms!important;
      overflow: hidden!important;
      border-bottom: 1px solid #e3e3e3;
   }
   .navbar-right {
       float: right!important;
       margin-right: 0px;
   }
   .container {
       width: 100%;
   }
 
   
    .custom-navbar.top-nav-collapse {
        padding: 12px 0;
        background: #fff;
        color: #666!important;
        transition-duration: 700ms!important;
        overflow: hidden!important;
        box-shadow: 0 0 2px rgba(50, 50, 50, 0.4);
   }
    .custom-navbar.top-nav-collapse li a, .top-nav-collapse .logo {
        color: #000;
   }
    .top-nav-collapse .st0{

        stroke:none;
   }
    .top-nav-collapse .register {
        color: #6e71f1!important;
        padding: 3px 15px!important;
        border: 1px solid #6e71f1;
        border-radius: 4px;
        margin-top: 10px;
   }
    .top-nav-collapse .register:hover {
        color: #fff!important;
        padding: 3px 15px;
        border: 1px solid #fff;
        border-radius: 4px;
        margin-top: 10px;
        background: #6e71f1!important;
   }
}
/* ========================================================================== home section ========================================================================== */
#home {
    background: #083a5f;
    overflow: visible;
    height: 70vh;
    text-align: center;
    padding-top: 50px;
}

#home img {
 height: 105%;
 width: auto;
 margin: 0 auto -16% auto;
}
#shop {
   background: #f9be29;
   overflow: hidden;
   max-height: 60vh;
   text-align: center;
   padding-top: 50px;
   background-image: url(../assets/images/bg.jpg);
   background-size: cover;
   background-position: left top;
}
#shop img{
 height: 110%;
 width: auto;
 margin: 0 auto;
 padding-top: 0%;
 overflow: hidden;
}
#loan {
   background: #35ab43;
   overflow: hidden;
   max-height: 60vh;
   text-align: center;
   padding-top: 50px;
   padding-top: 50px;
}
#loan img{
 height: 100%;
 width: auto;
 margin: 0 auto;
 padding-top: 5%;
}
#manage {
   background: #573282;
   overflow: hidden;
   max-height: 60vh;
   text-align: center;
   padding-top: 50px;
}
#manage img{
 height: 100%;
 width: auto;
 margin: 0 auto;
 padding-top: 5%;
}
#connect {
   background: #e36727;
   overflow: hidden;
   max-height: 60vh;
   text-align: center;
   padding-top: 50px;
}
#connect img{
 height: 100%;
 width: auto;
 margin: 0 auto;
 padding-top: 5%;
}
#airtime {
   background: #a8a8ab;
   overflow: hidden;
   max-height: 60vh;
   text-align: center;
   padding-top: 50px;
}
#airtime img{
 height: 100%;
 width: auto;
 margin: 0 auto;
 padding-top: 3%;
}
#acco{
   background: #05bae3;
   overflow: hidden;
   max-height: 60vh;
   text-align: center;
   padding-top: 50px;
}
#acco img{
 height: 100%;
 width: auto;
 margin: 0 auto;
 padding-top: 3%;
}
.text-center {
 text-align: center!important;
}
.favourite {
 width: 25px;
 margin-left: calc(50% - 12px);
 float: left;
 margin-top: -17px;
}
.white-top {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
   color: #fff;
   padding: 40px 20px 10px;
   line-height: 1.5;
   font-weight: 200;
}
.acco-price {
   font-size: 18px;
   font-weight: 500;
   color: #000;
   width: 100%;
   float: left;
   margin-bottom: 15px;
}
.icon {
   width: 25px;
   height: 25px;
   padding: 8px 3px 10px 3px;
   display: inline-block;
}
.acco-title {
   font-size: 16px;
   color: #000;
   padding: 15px 0 5px 0;
   width: 100%;
   float: left;
}
.large-num {
    font-size: 166px!important;
    font-weight: 900!important;
    margin-top: -15px;
}
.modal-margin {
    margin-top: 15px!important;
    margin-bottom: 25px!important;
}
.orange-title {
    font-weight: 400!important;
    font-size: 24px;
    color: #F37029;
}
.orange-btn {
    background: #F37029!important;
    border: 0px;
    border-radius: 4px;
    font-size: 14px;
    padding: 8px 17px;
    margin-top: 12px;
    transition: all 1.4s ease-in-out;
    color: #fff!important;
}
.green-title {
    font-weight: 400!important;
    font-size: 24px;
    color: #39B54A;
}
.green-btn {
    background: #39B54A!important;
    border: 0px;
    border-radius: 4px;
    font-size: 14px;
    padding: 12px 42px;
    margin-top: 12px;
    transition: all 1.4s ease-in-out;
    color: #000!important;
}
.yellow-title {
    font-weight: 400!important;
    font-size: 24px;
    color: #fcca0a;
}
.yellow-btn {
    background: #fcca0a!important;
    border: 0px;
    border-radius: 4px;
    font-size: 14px;
    padding: 12px 42px;
    margin-top: 12px;
    transition: all 1.4s ease-in-out;
    color: #000!important;
}
.dropdown-menu {
    margin: 0;
    Padding: 0;
    border-radius: 4px!important;
}
.dropdown-menu a {
    color: #333!important;
    text-align: center;
}
.blue-title {
    font-weight: 400!important;
    font-size: 24px;
    color: #2e9bd6;
}
.blue-btn {
    background: #2e9bd6!important;
    border: 0px;
    border-radius: 4px;
    font-size: 14px;
    padding: 12px 42px;
    margin-top: 12px;
    transition: all 1.4s ease-in-out;
    color: #000!important;
}
.wide-button {
    width: 250px;
    padding: 20px;
}
#home .btn:hover, #work .btn:hover, #promo .btn:hover, .fullwidth .btn:hover {
    background: #f98459;
    border-color: transparent;
}
#home .btn-danger {
    background: #00c6d7;
    border-color: transparent;
}
#home .btn-danger:hover {
    border: 4px solid #ffffff;
    border-radius: 0;
    background: transparent;
    color: #ffffff;
}
#form {
    height: auto;
    color: #333;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 100px;
    display: flex;
    align-items: center;
    background: #d40000;
    background: #fff;
}
textarea {
 height: 150px;
   border: none;
   padding: 5px 7px;
   border-bottom: 2px solid #000;
   background: #ededed;
   width: 100%;
   padding: 15px;
}

/* ========================================================================== work section ========================================================================== */
#work {
 padding: 14% 0 75px;
 background: #EDEDED;
 float: left;
 width: 100%;
 min-height: calc(100vh - 65px);
}
#fund {
    /* background-image: url(../images/fund-bg.jpg)!important; */
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    height: 100vh;
}
#clients {
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    text-align: center;
    padding-bottom: 200px;
    padding-top: 150px;
}
#promo {
    /* background-image: url(../images/contact-bg.jpg)!important; */
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    text-align: center;
    padding-bottom: 100px;
    padding-top: 180px;
    height: 100vh;
}
#work .col-md-4 {
    position: relative;
    transition: all 0.4s ease-in-out;
}
#work svg {
    width: 100%;
}
#work h3 {
    font-size: 20px;
    width: 100%;
    margin-top: 0;
}
.top-edge {
    margin-top: 0;
    color:#888;
    line-height: 1.3;
    margin-bottom: 0;
}
#info img {
    margin-bottom:0px;
}
.col-md-3 .top-edge {
    text-transform: uppercase;
}
.margin-15 {
    margin-bottom: 35px;
}
.year {
    font-size: 29px;
    font-weight: 900;
    color: rgb(255, 83, 83);
    line-height: 1;
    text-align: right;
}
.tables {
    display: table;
    margin: 0 auto;
}
ul#horizontal-list {
    list-style: none;
}
ul#horizontal-list li {
    display: inline;
}
#menu-outer {
    height: 84px;
}
.apo {
    position: relative;
    color: #fff;
    letter-spacing: 2px;
    margin-left: 10px;
}
.top-nav-collapse .apo {
    position: relative;
    color: #0e9ade;
    letter-spacing: 2px;
}
.apo svg {
    position: absolute;
    top: -8px;
    left: 0;
    letter-spacing: 1px;
}
/* ========================================================================== about section ========================================================================== */
#about {
    background: #f2f2f2;
    color: #333;
    text-align: center;
}
#about img {
    -ms-transform: rotate(0deg);
   /* IE 9 */
    -webkit-transform: rotate(0deg);
   /* Chrome, Safari, Opera */
    transform: rotate(0deg);
    transition-duration: 1000ms;
}
#about img:hover {
    -ms-transform: rotate(7deg);
   /* IE 9 */
    -webkit-transform: rotate(7deg);
   /* Chrome, Safari, Opera */
    transform: rotate(7deg);
    transition-duration: 1000ms;
    cursor: pointer;
}
.text_box, .text_area, .text_select {
    width: 100%;
    padding: 0;
    margin-bottom: 17px;
    font-size: 16px;
    font-family: 'lato';
    font-weight: 400;
    background-color: rgba(255, 255, 255, 0);
}
.nav-tabs {
    margin-top: -20px;
    border-bottom: none!important;
}
.nav-tabs > li {
    float: left;
    border-bottom: 1px solid #ff4141;
}
.nav-tabs > li > a {
    display: block;
    color: #ff4141;
    font-size: 16px;
    padding: 10px 20px;
    border: 0;
    border-radius: 0;
    margin-right: 0!important;
}
.nav-tabs > li > a:hover {
    background-color: transparent;
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    color: #fff;
    cursor: default;
    background-color: #ff4141;
    border: none!important;
    border-bottom-color: transparent;
}
.tab-content {
    /* padding-top: 20px; */
}
.tab-content a {
    color: #0CC;
}
.tab-content a:hover, .tab-content a:link {
    color: #FF0;
    text-decoration: none;
}
/* ========================================================================== team section ========================================================================== */
#team {
    text-align: center;
    background: #fff;
}
#team .social-icon li a {
    color: #fff;
}
#team .social-icon li a:hover {
    color: #00c6d7;
}
/* ========================================================================== portfolio section ========================================================================== */
#portfolio {
    background-color: #f6f6f6;
    text-align: center;
}
#portfolio .container {
    width: 100%;
    padding: 0;
    margin: 0;
}
#portfolio img {
    width: 100%;
    transition: all 0.4s ease-in-out;
}
#portfolio img:hover {
    opacity: 0.5;
}
/* FILTER CSS */
.filter-wrapper {
    width: 100%;
    margin: 40px 0 24px 0;
    overflow: hidden;
    text-align: center;
}
.filter-wrapper li {
    display: inline-block;
    margin: 4px;
}
.filter-wrapper li a {
    color: #999999;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding: 8px 17px;
    display: block;
    text-decoration: none;
    transition: all 0.4s ease-in-out;
}
.filter-wrapper li .selected, .filter-wrapper li a:focus, .filter-wrapper li a:hover {
    color: #00c6d7;
    outline: none;
}
/* ISOTOPE BOX CSS */
.iso-box-section {
    width: 100%;
}
.iso-box-wrapper {
    width: 100%;
    padding: 0;
    clear: both;
    position: relative;
}
.iso-box {
    position: relative;
    min-height: 50px;
    float: left;
    overflow: hidden;
    margin-bottom: 20px;
}
.iso-box > a {
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.iso-box > a {
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.fluid-img {
    width: 100%;
    display: block;
}
.zoomIn{
    padding:0;
}
/* ========================================================================== pricing section ========================================================================== */
#pricing {
    text-align: center;
}
#pricing h2 {
    font-size: 52px;
}
#pricing h3 {
    font-size: 16px;
}
#pricing .plan {
    box-shadow: 0px 2px 8px 0px rgba(50, 50, 50, 0.20);
    padding-top: 40px;
    padding-bottom: 80px;
    margin-bottom: 32px;
}
#pricing .plan span {
    font-size: 16px;
}
#pricing .plan .plan_title {
    font-weight: 300;
    letter-spacing: 2px;
    padding: 30px;
    margin-bottom: 20px;
}
#pricing .medium-icon {
    box-shadow: 0px 2px 8px 0px rgba(50, 50, 50, 0.20);
    border-radius: 50%;
    font-size: 52px;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
    margin-bottom: 32px;
}
#pricing .plan ul {
    padding: 0px;
}
#pricing .plan ul li {
    display: block;
    font-weight: 500;
    padding: 12px 0px;
}
#pricing .plan .btn {
    border: none;
    border-radius: 0px;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    padding: 16px 60px;
    transition: all 0.4s ease-in-out;
    margin-top: 20px;
    text-transform: uppercase;
}
#pricing .plan-one .btn {
    background-color: #00c6d7;
}
#pricing .plan-two .btn {
    background-color: #ffa400;
}
#pricing .plan-three .btn {
    background-color: #009988;
}
#pricing .plan .btn:hover {
    background-color: #3B3B3B;
}
/* ========================================================================== contact section ========================================================================== */
#contact {
    /* background: #3B3B3B url('../images/contact-bg.jpg') no-repeat center center; */
    background-size: cover;
    color: #ffffff;
}
#contact h2 {
    padding-bottom: 20px;
}
#contact h3 {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
}
#contact .medium-icon {
    padding-top: 10px;
    padding-right: 10px;
}
#contact .contact-info div {
    padding-top: 14px;
    padding-bottom: 14px;
}
#contact form {
    padding-top: 60px;
}
#contact .form-control {
    border: 2px solid #ffffff;
    border-radius: 0;
    box-shadow: none;
    margin-top: 10px;
    margin-bottom: 10px;
    transition: all 0.4s ease-in-out;
}
#contact input {
    height: 40px;
}
#contact input[type="submit"] {
    background: #00c6d7;
    border: 3px solid #00c6d7;
    letter-spacing: 1px;
    margin-top: 18px;
    height: 50px;
    color: #ffffff;
}
#contact input[type="submit"]:hover {
    color: #000000;
}
/* ========================================================================== footer section ========================================================================== */
footer {
   text-align: center;
   background-color: #fff!important;
   font-size: 12px!important;
   color: white;
   width: 100%;
   float: left;
   padding: 5px 0;
}
footer p {
    padding: 16px;
}
ul li {
    line-height: 22px;
    font-size: 14px;
    font-weight: 400!important;
    color: rgba(3,27,78,.7);
    line-height: 22px;
list-style: outside;
margin: 0 9px;
}
.social-icon {
    padding: 0;
    margin: 0;
}
.social-icon li {
    list-style: none;
    display: inline-block;
    padding: 6px;
}
.social-icon li a {
    box-shadow: 0px 2px 8px 0px rgba(50, 50, 50, 0.09);
    color: #808080;
    font-size: 20px;
    width: 56px;
    height: 56px;
    line-height: 60px;
    text-align: center;
    text-decoration: none;
    transition: all 0.4s ease-in-out;
}
.social-icon li a:hover {
    color: #00c6d7;
}
@media screen and (min-width: 992px) {
    @-moz-document url-prefix() {
        #portfolio .col-lg-4, #portfolio .col-md-4 {
            width: 33.333%;
       }
    
   }
    .top20{
        display: flex;
        align-items: center;
   }
   .blam {
       display: table!important;
       margin: 0 auto!important;
       float: none;
   }
   .container {
       width: 100%;
       max-width: 1170px;
   }
}
@media screen and (max-width: 991px) {
    .mobile-pic {
        margin-top:45px;
   }
    .btn-hover {
        width: 66px;
   }

    .manje {
        padding-top: 9px!important;
   }
   .img-bg .probootstrap-photo-details {
   text-align: left;
   bottom: 0px!important;
   position: absolute;
 }
    #info img{
        margin-top:80px;

   }
    #work h1 {
        font-size: 22px;
        line-height: 1.2;
        text-align: center;
   }
    .mobile-pic {
        display: none;
   }
    .heading-right, .heading-left {
        text-align: center!important;
   }
    .heading {
        font-size: 22px;

        margin-bottom: 8px;
        font-family: 'Jost';
        margin-top: 8px;
        
       
   }
   .med-heading {
       font-size: 22px;
       color: #12375d!important;
       margin-bottom: 8px;
       font-family: 'Jost';
       margin-top: 8px;
       text-transform: uppercase;
       font-weight: 800!important;
       text-align: center;
   }
    #info h1 {
        font-size: 22px;
        font-weight: 800!important;
        margin-top: 15px;
        text-align: center!important;
   }
    #info h1 br {
        display: none;
   }
    .text-right, .text-left {
        text-align: center!important;
   }
    #info .text-right,#info .text-left {
        text-align: left!important;
   }
    #work h1, #fund h1, #work p, #work h2 {
        font-family: 'Jost';
        width: 100%;
        text-align: center;
   }
    .job-text {
        text-align: center!important;
   }
    #info, #work, #form, #promo, #clients {
        height: auto!important;
        padding: 100px 0;
   }
    .for-mobile {
        display: block;
   }
    .vanish {
        display:none;
   }
    .top20 {
        padding-bottom: 0;
   }
    .member-right {
        margin-bottom: 0;
   }
    .para {
        line-height: 1.4;
        padding: 20px;
        float: none;
   }
}
@media screen and (max-width: 767px) {
    .mobile-pic {
        display:none;
   }
   #shop {
       overflow: hidden;
       height: auto!important;
       text-align: center;
       padding-top: 50px;
      
       background-size: cover;
       background-position: center;
   }

.big-pad {
   padding: 30px 5px!important;
}
   .hero {
     position: absolute; top: 35%; font-size: 22px; width: 100%; text-align: center; font-weight: 700; padding: 0 20px; line-height: 1.3;
   }
   .top-title {
       font-size: 23px!important;
       font-weight: 700;
       padding-bottom: 0px;
       padding: 12% 20px 0;
       text-shadow: 0px -1px 3px rgba(0, 0, 0, 0.8);
       line-height: 1.5;
   }
   .wrapper {
       padding-top: 0!important;
   }
   .modal-title {
   font-size: 20px;
   font-weight: 200;
   line-height: 1.2;
   width: 70%;
   margin: 0 auto;
   padding: 100px 0 70px;
}
    
    .container {
        width: 100%;
   }
    #work .row {
        display: block;
   }
    .top-nav-collapse .apo {
        color:#fff;
   }
    .top-nav-collapse .st2 {

   }
    .manje {
        padding-top: 9px!important;
   }
    .apo svg {
        width: 152px;
   }
    #work h1 {
        font-size: 22px;
        
        line-height: 1.2;
        text-align: center;
   }
    #info h1 {
        font-size: 22px;
        font-weight: 800!important;
        margin-top: 15px;
        text-align: center!important;
   }
    #info h1 br {
        display: none;
   }
    .text-right, .text-left {
        text-align: center!important;
   }
    #info .text-right,#info .text-left {
        text-align: left!important;
   }
    #work h1, #work p, #work h2 {
        font-family: 'Jost';
        width: 100%;
        text-align: center;
   }
    .itemu {
        font-size: 18px;
        animation-duration: 10s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        text-transform: uppercase;
        font-weight: 800;
        line-height: 1.2;
   }
    .top-nav-collapse .rogo {
        color: #fff!important;
   }
    .top-nav-collapse .globe {
        fill: #fff;
   }
    .custom-navbar .nav li.active > a {
        color: #0d4c79;
        opacity: 0.6;
   }
    #home {
        /* background-image: url(../images/apo.jpg)!important; */
        background-size: cover;
        background-position: center center;
   }
    .item-1, .item-2, .item-3 {
        font-size: 20px!important;
        margin-top: -50px;
   }
    .year{
        text-align: left;
   }
    .category-title {
        font-size: 23px!important;
        margin-bottom: -19px;
        padding-top: 0px;
   }
}
/* ========================================================================== media quires for mobile ========================================================================== */
@media (max-width: 640px) {
    .large-num {
        font-size: 50px!important;
        font-weight: 900!important;
        margin-top: 0px;
   }
}
@media (max-width: 440px) {
    #home img{
     height: auto;
   }
    #home h1 {
        font-size: 22px;
   }
    #home h3 {
        font-size: 12px;
   }
}
.category-title {
    font-size: 50px;
    font-weight: 200;
    margin-bottom: -19px;
}
.category-section {
    color: #ffffff;
    height: 90vh;
    text-align: center;
}
.top-title {
   font-size: 33px;
   font-weight: 700;
   padding: 5% 20px 0;
   text-shadow: 0px -1px 3px rgba(0, 0, 0, 0.8);
}
.category-bottom {
    background-color: white;
    background-size: cover;
    background-position: center;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 90px 0;
    text-align: center;
    color: #444;
    font-weight: 500;
    line-height: 1.4;
}
#tag .col-md-5 img {
    border-radius: 50%;
    margin-top: 30px;
    width: 80%
}
#tag .col-md-4 .col-md-12.nopad {
}
#tag b {
    font-weight: 800;
}
.item-1, .item-2, .item-3 {
    position: absolute;
    display: block;
    width: 55%;
    font-size: 28px;
    animation-duration: 20s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    text-transform: uppercase;
    font-weight: 800;
    line-height: 1;
}
.item-1 span, .item-2 span, .item-3 span {
    font-weight:100;
}
.item-1{
    animation-name: anim-1;
}
.item-2{
    animation-name: anim-2;
}
.item-3{
    animation-name: anim-3;
}
.item-4{
    animation-name: anim-4;
}
@keyframes anim-1 {
    0%, 8.3% {
        left: -100%;
        opacity: 0;
   }
    8.3%, 25% {
        left: 25%;
        opacity: 1;
   }
    33.33%, 100% {
        left: 110%;
        opacity: 0;
   }
}
@keyframes anim-2 {
    0%, 33.33% {
        left: -100%;
        opacity: 0;
   }
    41.63%, 58.29% {
        left: 25%;
        opacity: 1;
   }
    66.66%, 100% {
        left: 110%;
        opacity: 0;
   }
}
@keyframes anim-3 {
    0%, 66.66% {
        left: -100%;
        opacity: 0;
   }
    74.96%, 91.62% {
        left: 25%;
        opacity: 1;
   }
    100% {
        left: 110%;
        opacity: 0;
   }
}
@keyframes anim-4 {
    0%, 66.66% {
        left: -100%;
        opacity: 0;
   }
    74.96%, 91.62% {
        left: 25%;
        opacity: 1;
   }
    100% {
        left: 110%;
        opacity: 0;
   }
}
.pat1 {
    /* background:url('../images/pat1.jpg') no-repeat center center; */
    background-size: cover;
    color: #ffffff;
    height: 294px;
    font-weight: 900;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
}
.pat2 {
    /* background:url('../images/pat2.jpg') no-repeat center center; */
    background-size: cover;
    color: #ffffff;
    height: 294px;
    font-weight: 900;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
}
.pat3 {
    /* background:url('../images/pat3.jpg') no-repeat center center; */
    background-size: cover;
    color: #ffffff;
    height: 294px;
    font-weight: 900;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
}
.category-section b {
    font-weight: 900;
}
.no-pad {
    padding-left: 0!important;
    padding-right: 0!important;
}
.job-title {
    margin-top: -4px;
    margin-bottom: 2px;
}
.cars-button {
    width: 170px;
    background-color: #ff5353;
    color: #fff;
    padding: 6px 29px 8px 29px;
    border-radius: 2px;
    text-align: center;
    margin: 0 auto;
    text-decoration: none;
}
.bottom-10 {
    margin-bottom: 10px;
}
.job-text {
    text-align: left;
}
.job-icon {
    margin: 0 auto;
    padding-bottom: 20px;
}
.job {
    margin-bottom: 39px;
    padding-bottom: 28px;
    border-bottom: 1px solid #ddd;
}
.fullscreen-bg {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: -6;
    position: fixed;
    background: #15141a;
}
.fullscreen-bg__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
@media (min-aspect-ratio: 16/9) {
    .fullscreen-bg__video {
        height: 300%;
        top: -100%;
   }
}
@media (max-aspect-ratio: 16/9) {
    .fullscreen-bg__video {
        width: 300%;
        left: -100%;
   }
}
@media (max-width: 767px) {
    .fullscreen-bg {
        /* background: url('../images/apo.jpg') cover no-repeat; */
   }
    .category-section {
        /* background-image: url(../images/apo.jpg); */
        background-size: cover;
        background-position: center;
   }
    .fullscreen-bg__video {
        display: none;
        /* background: url('./images/apo.jpg') cover no-repeat; */
   }
}
a.btn-login {
    margin-top:3px;
    width: auto;
    margin-right: 0;
}
#modal-09 {
    animation-duration: 0.2s!important;
    /* background-image: url(../images/blur-overlay.png)!important; */
    background-size: cover;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.98)!important;
}
.login-title {
    text-transform: uppercase;
    color: #fff;
    margin-top: 7px;
}
.top-15 {
    margin-top: 15px;
}
footer a {
    color: #777;
}
.card-text {
padding: 15px;
background: #e2e2e2;
font-weight: 600!important;
margin-top:-3px;
text-overflow: ellipsis;
white-space: nowrap;
overflow: hidden;
}
.card-img-top {
  width: 100%;
  height: auto;
}
.card {
 margin-bottom: 25px;
}
.call-me {
 position: fixed;
 bottom: 20px;
 right: 20px;
 Padding: 15px;
 background-color: #f8ca09;
     z-index: 300;
     color: #000;
 font-size: 12px;
 border-radius: 4px;
 text-transform: uppercase;
 font-weight: 600;
 width: 130px;
 text-align: center;
}
.need-loan {
 position: fixed;
 bottom: 77px;
 right: 20px;
 Padding: 15px;
 background-color: #f36f2b;
 z-index: 300;
 color: #fff;
 font-size: 12px;
 border-radius: 4px;
 text-transform: uppercase;
 font-weight: 600;
 width: 130px;
 text-align: center;
}
.need-loan:hover {
 color:#fff;
 background-color: #450087;
}
.call-me:hover {
 color:#fff;
 background-color: #450087;
}

@media (max-width: 991px) {
   .navbar-header {
       float: none;
   }
   .navbar-left,.navbar-right {
       float: none !important;
   }
   .navbar-toggle {
       display: block;
   }
   .navbar-collapse {
       border-top: 1px solid transparent;
       box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
   }
   .navbar-fixed-top {
       top: 0;
       border-width: 0 0 1px;
   }
 
   .navbar-nav {
       float: none!important;
       margin-top: 7.5px;
   }
   .navbar-nav>li {
       float: none;
   }
   .navbar-nav>li>a {
       padding-top: 10px;
       padding-bottom: 10px;
   }
   .collapse.in{
       display:block !important;
   }
}

.legal h1 {
 line-height: 29px;
   font-size: 22px;
   font-weight: 800!important;
   color: rgba(3,27,78,.7);
}
.img-bg {
 background-size: cover;
 background-position: center center;
 background-repeat: no-repeat;
 height: 500px;
 margin-bottom: 16px;
 display: block;
}
.probootstrap-animate {
 opacity: 0;
 visibility: hidden;
}

@media screen and (max-width: 1200px) {
 .img-bg {
   height: 350px;
 }
}
.img-bg:hover p, .img-bg:hover h2 {
 margin-left: 10px;
}

.img-bg .probootstrap-photo-details {
 text-align: left;
   bottom: 16px;
   left: 15px;
   right: 15px;
   position: absolute;
   padding: 40px 15px 10px;
   /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1e5799+0,ffffff+0,000000+100 */
background: rgb(30,87,153); /* Old browsers */
background: -moz-linear-gradient(top, rgba(30,87,153,1) 0%, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(30,87,153,1) 0%,rgba(255,255,255,0) 0%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(30,87,153,1) 0%,rgba(255,255,255,0) 0%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

.img-bg .probootstrap-photo-details h2, .img-bg .probootstrap-photo-details p {
 color: #ffffff;
 position: relative;
}
.probootstrap-photo-details p {
 color: #fff!important;
}
.img-bg .probootstrap-photo-details h2 {
 margin: 0;
 font-weight: bold;
}

.img-bg .probootstrap-photo-details p {
 margin: 0;
 padding: 0;
 color: rgba(255, 255, 255, 0.9);
font-weight: 400!important;
}

.mobile-promo {
 width:100%;
 float: left;
 height: 100vh;
 /* background-image: url(../images/promo-small.jpg); */
 background-size: cover;
 background-position: center;
 position: relative;
}

.mobile-only {
 display: none!important;
}
@media (max-width: 991px) {
  .desktop-only {
    display: none!important;
  }
  #fund {
   height: auto;
   padding: 30px;
}
.top-edge {
 text-align: center!important;
}
.itemu .yellow-title {
 font-size: 15px;
}
.hero {

   font-weight: 200;

}
  .mobile-only {
    display: block!important;
  }
  #promo {
    background-image: none!important;
    background-color: #080808;
  }
}

.error {
    margin-left: 7%;
    padding: 10px 0px;
  }

  .hover-effect {
    cursor: pointer;
  }

  .hover-effect:hover {
    background-color: #f0f0f0; /* Change the background color to your desired hover color */
  }